import React, { useState, useRef, useEffect } from 'react';
import OpenAI from 'openai';
import { firestoreFunctions } from './firestoreFunctions';
import { marked } from 'marked';
import InfoTooltip from '../common/InfoTooltip';
import { motion, AnimatePresence } from 'framer-motion';

const NBAChat = ({ game, onClose, SuggestionBubbles, conversationStarters }) => {
  const { teams } = game;
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([
    { 
      text: `Hi! I can help you analyze the ${game.teams.away.name} vs ${game.teams.home.name} NBA game. What would you like to know?`, 
      isAi: true 
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const messagesEndRef = useRef(null);

  const openai = new OpenAI({
    apiKey: import.meta.env.VITE_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });

  useEffect(() => {
    const initializeThread = async () => {
      try {
        const thread = await openai.beta.threads.create();
        setThreadId(thread.id);

        const predictionData = await firestoreFunctions.getPredictionData({
          gameId: game.id.toString(),
          sport: 'NBA'
        });

        if (!predictionData.success) {
          console.error('NBA prediction data error:', predictionData.error);
          throw new Error('Failed to fetch NBA prediction data');
        }

        const homeTeam = predictionData.data.teams?.home || {};
        const awayTeam = predictionData.data.teams?.away || {};
        const odds = predictionData.data.odds || {};
        const draftkingsMarkets = odds?.draftkings?.markets || {};

        // Get betting odds data
        const moneylineHome = draftkingsMarkets.h2h?.outcomes?.[homeTeam.name.toLowerCase()]?.price;
        const moneylineAway = draftkingsMarkets.h2h?.outcomes?.[awayTeam.name.toLowerCase()]?.price;
        const spreadHome = draftkingsMarkets.spreads?.outcomes?.[homeTeam.name.toLowerCase()];
        const spreadAway = draftkingsMarkets.spreads?.outcomes?.[awayTeam.name.toLowerCase()];
        const totalOver = draftkingsMarkets.totals?.outcomes?.over;
        const totalUnder = draftkingsMarkets.totals?.outcomes?.under;

        await openai.beta.threads.messages.create(
          thread.id,
          {
            role: "user",
            content: `NBA Game Analysis Context:
                     
                     Game Information:
                     - Matchup: ${awayTeam.name} @ ${homeTeam.name}
                     - Date: ${predictionData.data.date}
                     - Predicted Total Points: ${predictionData.data.predictedTotalPoints}
                     
                     Betting Odds (DraftKings):
                     Money Line:
                     - ${homeTeam.name}: ${moneylineHome || 'N/A'}
                     - ${awayTeam.name}: ${moneylineAway || 'N/A'}
                     
                     Spread:
                     - ${homeTeam.name}: ${spreadHome ? `${spreadHome.point} (${spreadHome.price})` : 'N/A'}
                     - ${awayTeam.name}: ${spreadAway ? `${spreadAway.point} (${spreadAway.price})` : 'N/A'}
                     
                     Total Points:
                     - Over: ${totalOver ? `${totalOver.point} (${totalOver.price})` : 'N/A'}
                     - Under: ${totalUnder ? `${totalUnder.point} (${totalUnder.price})` : 'N/A'}
                     
                     Team Season Statistics:
                     
                     ${homeTeam.name} (${homeTeam.seasonStats?.apiStats?.games?.overall?.wins}-${homeTeam.seasonStats?.apiStats?.games?.overall?.losses}):
                     Offensive Profile:
                     - Points Per Game: ${homeTeam.seasonStats?.apiStats?.points?.scoring?.overall?.average || 'N/A'}
                     - Field Goal %: ${homeTeam.seasonStats?.apiStats?.fieldGoals?.percentage || 'N/A'}%
                     - 3PT %: ${homeTeam.seasonStats?.apiStats?.threePoints?.percentage || 'N/A'}%
                     - Free Throw %: ${homeTeam.seasonStats?.apiStats?.freeThrows?.percentage || 'N/A'}%
                     
                     Defensive & Other:
                     - Points Against: ${homeTeam.seasonStats?.apiStats?.points?.against?.overall?.average || 'N/A'}
                     - Rebounds: ${homeTeam.seasonStats?.apiStats?.rebounds?.total || 'N/A'} per game
                     - Assists: ${homeTeam.seasonStats?.apiStats?.assistsPerGame || 'N/A'}
                     - Turnovers: ${homeTeam.seasonStats?.apiStats?.turnoversPerGame || 'N/A'}
                     
                     ${awayTeam.name} (${awayTeam.seasonStats?.apiStats?.games?.overall?.wins}-${awayTeam.seasonStats?.apiStats?.games?.overall?.losses}):
                     Offensive Profile:
                     - Points Per Game: ${awayTeam.seasonStats?.apiStats?.points?.scoring?.overall?.average || 'N/A'}
                     - Field Goal %: ${awayTeam.seasonStats?.apiStats?.fieldGoals?.percentage || 'N/A'}%
                     - 3PT %: ${awayTeam.seasonStats?.apiStats?.threePoints?.percentage || 'N/A'}%
                     - Free Throw %: ${awayTeam.seasonStats?.apiStats?.freeThrows?.percentage || 'N/A'}%
                     
                     Defensive & Other:
                     - Points Against: ${awayTeam.seasonStats?.apiStats?.points?.against?.overall?.average || 'N/A'}
                     - Rebounds: ${awayTeam.seasonStats?.apiStats?.rebounds?.total || 'N/A'} per game
                     - Assists: ${awayTeam.seasonStats?.apiStats?.assistsPerGame || 'N/A'}
                     - Turnovers: ${awayTeam.seasonStats?.apiStats?.turnoversPerGame || 'N/A'}
                     
                     Key Players Performance:
                     
                     ${homeTeam.name} Impact Players:
                     Scoring Leaders:
                     ${homeTeam.keyPlayers?.topScorers?.map(player => 
                       `- ${player.name}: ${player.pointsPerGame} PPG, ${player.efficiency} EFF in ${player.minutesPerGame} MPG (${player.gamesPlayed} games)`
                     )?.join('\n') || 'No data available'}
                     
                     Playmaking:
                     ${homeTeam.keyPlayers?.topPlaymakers?.map(player => 
                       `- ${player.name}: ${player.assistsPerGame} APG in ${player.minutesPerGame} MPG (${player.gamesPlayed} games)`
                     )?.join('\n') || 'No data available'}
                     
                     Rebounding:
                     ${homeTeam.keyPlayers?.topRebounders?.map(player => 
                       `- ${player.name}: ${player.reboundsPerGame} RPG (${player.per36Rebounds} per 36) in ${player.minutesPerGame} MPG`
                     )?.join('\n') || 'No data available'}
                     
                     ${awayTeam.name} Impact Players:
                     Scoring Leaders:
                     ${awayTeam.keyPlayers?.topScorers?.map(player => 
                       `- ${player.name}: ${player.pointsPerGame} PPG, ${player.efficiency} EFF in ${player.minutesPerGame} MPG (${player.gamesPlayed} games)`
                     )?.join('\n') || 'No data available'}
                     
                     Playmaking:
                     ${awayTeam.keyPlayers?.topPlaymakers?.map(player => 
                       `- ${player.name}: ${player.assistsPerGame} APG in ${player.minutesPerGame} MPG (${player.gamesPlayed} games)`
                     )?.join('\n') || 'No data available'}
                     
                     Rebounding:
                     ${awayTeam.keyPlayers?.topRebounders?.map(player => 
                       `- ${player.name}: ${player.reboundsPerGame} RPG (${player.per36Rebounds} per 36) in ${player.minutesPerGame} MPG`
                     )?.join('\n') || 'No data available'}
                     
                     Home/Away Splits:
                     ${homeTeam.name} Home: ${homeTeam.seasonStats?.apiStats?.games?.home?.wins}-${homeTeam.seasonStats?.apiStats?.games?.home?.losses} (${(homeTeam.seasonStats?.apiStats?.games?.home?.winPercentage * 100).toFixed(1)}%)
                     ${awayTeam.name} Away: ${awayTeam.seasonStats?.apiStats?.games?.away?.wins}-${awayTeam.seasonStats?.apiStats?.games?.away?.losses} (${(awayTeam.seasonStats?.apiStats?.games?.away?.winPercentage * 100).toFixed(1)}%)
                     
                     Please provide NBA-specific analysis using this comprehensive data set.`
          }
        );
      } catch (error) {
        console.error('Error initializing NBA thread:', error);
        setMessages(prev => [...prev, { 
          text: "I apologize, but I'm having trouble accessing the NBA game data. Please try again.", 
          isAi: true 
        }]);
      }
    };

    initializeThread();
  }, [game]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || !threadId) return;

    const userMessage = inputMessage.trim();
    setInputMessage('');
    setMessages(prev => [...prev, { text: userMessage, isAi: false }]);
    setIsLoading(true);

    try {
      await openai.beta.threads.messages.create(
        threadId,
        {
          role: "user",
          content: userMessage
        }
      );

      const run = await openai.beta.threads.runs.create(
        threadId,
        {
          assistant_id: "asst_gYOFAD8578fB7l1lKozgzNt7",
          instructions: `You are an expert NBA analyst and sports betting advisor. Your role is to provide data-driven analysis using the comprehensive game data provided. Follow these guidelines:

          DATA UTILIZATION:
          1. Game Context:
             - Use team records (home/away/overall) for matchup context
             - Compare scoring averages (points for/against) in home/away situations
             - Reference the predicted total points when discussing over/under
          
          2. Player Analysis:
             - Analyze recent form using the 10-game trend data
             - Compare season averages vs recent performance
             - Highlight significant trends (increasing/decreasing) in key stats
             - Consider minutes played when evaluating impact
          
          3. Betting Analysis:
             - Reference specific odds from both DraftKings and FanDuel
             - Compare line movements when relevant
             - Analyze spreads in context of home/away performance
             - Consider totals in relation to team scoring trends
          
          4. Statistical Deep Dives:
             - Use shooting percentages (FG%, 3P%, FT%) for efficiency analysis
             - Compare rebounding numbers (offensive/defensive splits)
             - Analyze turnover rates and assist numbers
             - Consider pace factors using FGA and possession stats

          RESPONSE FORMATS:
          1. For Quick Questions:
             - Direct answer with key supporting stat
             - One relevant trend or insight
          
          2. For Player Analysis:
             - Recent form (10-game trends)
             - Season averages comparison
             - Matchup-specific insights
          
          3. For Betting Questions:
             - Relevant odds from both books
             - Key statistical support
             - Trend analysis
             - Risk factors

          4. For Team Matchups:
             - Head-to-head statistical comparison
             - Home/Away performance impact
             - Key player matchups
             - Pace and style considerations

          IMPORTANT GUIDELINES:
          - Always cite specific stats when making claims
          - Highlight significant trends from the data
          - Consider home/away splits in analysis
          - Note any recent form changes
          - Acknowledge data limitations when present
          - Use bullet points for clarity in longer responses
          - Format numbers consistently (1 decimal place for percentages, whole numbers for counting stats)
          - Include both positive and negative factors in analysis`
        }
      );

      let response;
      while (true) {
        const runStatus = await openai.beta.threads.runs.retrieve(
          threadId,
          run.id
        );

        if (runStatus.status === 'completed') {
          const messages = await openai.beta.threads.messages.list(
            threadId
          );
          response = messages.data[0].content[0].text.value;
          break;
        } else if (runStatus.status === 'failed') {
          throw new Error('Run failed');
        }

        await new Promise(resolve => setTimeout(resolve, 500));
      }

      setMessages(prev => [...prev, { text: response, isAi: true }]);
    } catch (error) {
      console.error('Error in NBA chat:', error);
      setMessages(prev => [...prev, { 
        text: "I apologize, but I encountered an error processing your NBA-related request.", 
        isAi: true 
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleClearChat = () => {
    setMessages([
      { text: 'How can I help analyze this NBA game?', isAi: true }
    ]);
  };

  const formatAiMessage = (message) => {
    const renderedMessage = marked(message);
    return (
      <div
        className="prose prose-invert max-w-none"
        dangerouslySetInnerHTML={{ __html: renderedMessage }}
      ></div>
    );
  };

  const handleSuggestionSelect = (suggestion) => {
    setInputMessage(suggestion);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 0.3
      }
    },
    exit: { 
      opacity: 0,
      transition: {
        duration: 0.2
      }
    }
  };

  const contentVariants = {
    hidden: { 
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  const messageVariants = {
    hidden: { 
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <motion.div 
      className="fixed inset-0 bg-slate-900/90 backdrop-blur-sm flex items-center justify-center z-50 p-4"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div 
        className="bg-[#1a1f2e] rounded-2xl w-full max-w-2xl shadow-2xl max-h-[90vh] flex flex-col border border-white/[0.08]"
        variants={contentVariants}
      >
        {/* Game Header */}
        <div className="p-6 bg-gradient-to-b from-slate-800 to-transparent rounded-t-2xl relative">
          <div className="absolute inset-0 bg-gradient-to-br from-indigo-500/[0.03] via-transparent to-violet-500/[0.03] rounded-t-2xl animate-pulse" />
          
          {/* Beta badge */}
          <motion.div 
            className="absolute top-4 right-4 flex items-center gap-2"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5 }}
          >
            <span className="text-xs text-slate-400">NBA AI Assistant in Beta</span>
            <span className="flex h-2 w-2">
              <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-indigo-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-indigo-500"></span>
            </span>
          </motion.div>

          {/* Teams */}
          <div className="relative flex items-center justify-center gap-6">
            <motion.div 
              className="flex flex-col items-center"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
            >
              <motion.div 
                className="w-16 h-16 bg-slate-800/50 rounded-xl p-2 border border-white/[0.08]"
                whileHover={{ scale: 1.05, rotate: [-1, 1, -1] }}
              >
                <img src={teams.away.logo} alt={teams.away.name} className="w-full h-full object-contain" />
              </motion.div>
              <div className="text-slate-200 mt-2 font-medium">{teams.away.name}</div>
            </motion.div>

            <motion.div 
              className="text-2xl font-bold text-slate-400"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.3 }}
            >
              vs
            </motion.div>

            <motion.div 
              className="flex flex-col items-center"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
            >
              <motion.div 
                className="w-16 h-16 bg-slate-800/50 rounded-xl p-2 border border-white/[0.08]"
                whileHover={{ scale: 1.05, rotate: [-1, 1, -1] }}
              >
                <img src={teams.home.logo} alt={teams.home.name} className="w-full h-full object-contain" />
              </motion.div>
              <div className="text-slate-200 mt-2 font-medium">{teams.home.name}</div>
            </motion.div>
          </div>
        </div>

        {/* Chat Header */}
        <div className="flex items-center justify-between p-4 border-b border-white/[0.08]">
          <motion.h3 
            className="text-lg font-medium bg-gradient-to-r from-indigo-400 to-violet-400 bg-clip-text text-transparent"
            animate={{ 
              opacity: [0.8, 1, 0.8],
              scale: [1, 1.02, 1]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            NBA Game Analysis
          </motion.h3>
          <div className="flex gap-2">
            <motion.button
              onClick={handleClearChat}
              className="text-slate-400 hover:text-white text-sm px-3 py-1.5 rounded-full bg-slate-800/50 border border-white/[0.05]"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Clear Chat
            </motion.button>
            <motion.button
              onClick={onClose}
              className="text-slate-400 hover:text-white w-8 h-8 rounded-full bg-slate-800/50 border border-white/[0.05] flex items-center justify-center"
              whileHover={{ scale: 1.1, rotate: 90 }}
              whileTap={{ scale: 0.9 }}
            >
              ✕
            </motion.button>
          </div>
        </div>

        {/* Chat Content */}
        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          <AnimatePresence mode="popLayout">
            {messages.map((message, index) => (
              <motion.div 
                key={index} 
                className={`${message.isAi ? 'text-slate-200' : 'text-indigo-400 text-right'}`}
                variants={messageVariants}
                initial="hidden"
                animate="visible"
                layout
              >
                {message.isAi ? (
                  <motion.div 
                    className="bg-slate-800/30 rounded-2xl p-4 border border-white/[0.05]"
                    whileHover={{ scale: 1.01, x: message.isAi ? 2 : -2 }}
                  >
                    {formatAiMessage(message.text)}
                  </motion.div>
                ) : (
                  <motion.div 
                    className="bg-indigo-500/10 rounded-2xl p-4 border border-indigo-500/20"
                    whileHover={{ scale: 1.01, x: -2 }}
                  >
                    {message.text}
                  </motion.div>
                )}
              </motion.div>
            ))}
          </AnimatePresence>
          
          {isLoading && (
            <motion.div 
              className="flex items-center justify-center gap-2 text-slate-400"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <motion.div 
                className="w-2 h-2 rounded-full bg-indigo-500"
                animate={{ 
                  scale: [1, 1.5, 1],
                  opacity: [0.5, 1, 0.5]
                }}
                transition={{ duration: 1, repeat: Infinity }}
              />
              <motion.div 
                className="w-2 h-2 rounded-full bg-indigo-500"
                animate={{ 
                  scale: [1, 1.5, 1],
                  opacity: [0.5, 1, 0.5]
                }}
                transition={{ duration: 1, repeat: Infinity, delay: 0.2 }}
              />
              <motion.div 
                className="w-2 h-2 rounded-full bg-indigo-500"
                animate={{ 
                  scale: [1, 1.5, 1],
                  opacity: [0.5, 1, 0.5]
                }}
                transition={{ duration: 1, repeat: Infinity, delay: 0.4 }}
              />
            </motion.div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <div className="p-4 border-t border-white/[0.08]">
          <div className="mb-4">
            {messages.length === 1 && (
              <SuggestionBubbles 
                onSelect={handleSuggestionSelect} 
                sport="NBA"
              />
            )}
          </div>
          
          <form onSubmit={handleSubmit} className="flex gap-2">
            <motion.input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Ask about this NBA game..."
              className="flex-1 bg-slate-800/50 rounded-xl px-4 py-3 text-slate-200 focus:outline-none border border-white/[0.05] placeholder-slate-400"
              whileFocus={{ scale: 1.01 }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
            />
            <motion.button
              type="submit"
              disabled={!inputMessage.trim()}
              className="bg-gradient-to-r from-indigo-500 to-violet-500 text-white px-6 py-3 rounded-xl font-medium disabled:opacity-50 disabled:cursor-not-allowed disabled:from-slate-600 disabled:to-slate-700"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              transition={{ type: "spring", stiffness: 400, damping: 25 }}
            >
              Send
            </motion.button>
          </form>

          {/* Beta indicator */}
          <motion.div 
            className="mt-4 flex items-center justify-center gap-2 text-xs text-slate-500"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1 }}
          >
            <span className="flex h-1.5 w-1.5">
              <span className="animate-ping absolute inline-flex h-1.5 w-1.5 rounded-full bg-indigo-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-indigo-500"></span>
            </span>
            <span>NBA AI Assistant in Beta • Improving with each interaction</span>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default NBAChat;
