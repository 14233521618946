import React, { useEffect, useState, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import GamePreviewCard from './GamePreviewCard';
import { collection, query, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';

const GamePreviewScroll = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const scrollContainerRef = useRef(null);
  const controls = useAnimation();

  // Auto-scroll animation
  useEffect(() => {
    let timeoutId;
    const startAutoScroll = () => {
      if (scrollContainerRef.current && !isHovered) {
        const scrollWidth = scrollContainerRef.current.scrollWidth;
        const clientWidth = scrollContainerRef.current.clientWidth;
        
        controls.start({
          x: -(scrollWidth - clientWidth),
          transition: {
            duration: 20,
            ease: "linear",
            repeat: Infinity,
            repeatType: "reverse"
          }
        });
      }
    };

    if (!loading && games.length > 0) {
      timeoutId = setTimeout(startAutoScroll, 1000);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      controls.stop();
    };
  }, [loading, games, isHovered, controls]);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        // Fetch NBA odds to get game IDs
        const nbaOddsRef = collection(db, 'nba_odds');
        const nbaOddsSnapshot = await getDocs(query(nbaOddsRef));
        
        // Extract NBA game IDs from odds records
        const nbaGameIds = new Set();
        nbaOddsSnapshot.docs.forEach(doc => {
          // Extract gameId from the document name format: odds_432350_XXXXXX
          const gameId = doc.id.split('_')[1];
          if (gameId) nbaGameIds.add(gameId);
        });

        // Fetch NBA games and predictions
        const nbaGamesRef = collection(db, 'nba_games');
        const nbaGamesSnapshot = await getDocs(query(nbaGamesRef));
        
        const nbaGamesData = nbaGamesSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(game => nbaGameIds.has(game.id.toString()))
          .map(game => {
            // Get odds for this specific game
            const gameOdds = nbaOddsSnapshot.docs
              .filter(doc => doc.id.includes(`odds_${game.id}_`))
              .map(doc => doc.data());
            
            return {
              ...game,
              odds: gameOdds,
              sport: 'NBA'
            };
          });

        // Get predictions for NBA games
        const gamesWithPredictions = await Promise.all(nbaGamesData.map(async (game) => {
          try {
            const predictionRef = doc(db, 'nba_betPredictions', `ai_${game.id}`);
            const predictionDoc = await getDoc(predictionRef);
            
            if (predictionDoc.exists()) {
              return {
                ...game,
                predictions: predictionDoc.data()
              };
            }
            return game;
          } catch (error) {
            console.error(`Error fetching predictions for ${game.id}:`, error);
            return game;
          }
        }));

        // Take only 2 NBA games instead of 6
        const validGames = gamesWithPredictions.slice(0, 2);
        setGames(validGames);
      } catch (error) {
        console.error('Error fetching games:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="w-full flex justify-center items-center py-4"
      >
        <div className="text-center text-gray-400">
          <p>Loading game previews...</p>
        </div>
      </motion.div>
    );
  }

  if (!games.length) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="w-full flex justify-center items-center py-4"
      >
        <div className="text-center text-gray-400">
          <p>No upcoming games available</p>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full relative"
    >
      {/* Title */}
      <div className="text-center mb-4">
        <h2 className="text-xl font-bold text-white mb-1">
          NBA Game Predictions
        </h2>
        <p className="text-sm text-gray-400">
          Preview our AI-powered NBA predictions
        </p>
      </div>

      {/* Gradient Edges */}
      <div className="absolute left-0 top-0 bottom-0 w-20 bg-gradient-to-r from-[#0B1120] to-transparent z-10" />
      <div className="absolute right-0 top-0 bottom-0 w-20 bg-gradient-to-l from-[#0B1120] to-transparent z-10" />

      {/* Scrolling Container */}
      <div 
        className="overflow-hidden touch-pan-x"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={scrollContainerRef}
      >
        <motion.div 
          className="flex gap-4 py-4 px-20"
          animate={controls}
          drag="x"
          dragConstraints={scrollContainerRef}
          onDragStart={() => controls.stop()}
          dragElastic={0.2}
        >
          {games.map((game) => (
            <motion.div
              key={game.id}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
              className="flex-shrink-0"
            >
              <GamePreviewCard game={game} />
            </motion.div>
          ))}
          
          {/* Call-to-Action Card */}
          <motion.div
            className="relative bg-gradient-to-br from-blue-500/20 to-purple-500/20 backdrop-blur-xl rounded-xl overflow-hidden border border-white/[0.15] min-w-[250px] max-w-[250px] flex flex-col items-center justify-center p-4 text-center flex-shrink-0"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300, damping: 15 }}
          >
            <motion.div 
              className="w-10 h-10 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center mb-3"
              animate={{ 
                scale: [1, 1.1, 1],
                rotate: [0, 5, -5, 0]
              }}
              transition={{ duration: 3, repeat: Infinity }}
            >
              <span className="text-xl">🎯</span>
            </motion.div>
            <h3 className="text-base font-bold text-white mb-2">
              Want More Insights?
            </h3>
            <p className="text-xs text-gray-400 mb-3">
              Get access to all predictions and expert insights
            </p>
            <motion.button
              className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-1.5 rounded-lg font-semibold text-sm hover:shadow-lg hover:shadow-blue-500/25 transition-all"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Sign Up Now →
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default GamePreviewScroll; 