import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Tab } from '@headlessui/react';
import { usePlayerProps } from '../../hooks/usePlayerProps';
import { useNBAPlayerStats } from '../../hooks/sports/useNBAPlayerStats';
import { 
  ArrowTrendingUpIcon as TrendingUpIcon, 
  ArrowTrendingDownIcon as TrendingDownIcon,
  ChartBarIcon,
  XMarkIcon
} from '@heroicons/react/24/solid';
import { usePlayerPropsPredictions } from '../../hooks/usePlayerPropsPredictions';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useBacktestingAnalytics } from '../../hooks/useBacktestingAnalytics';

const PlayerPropsModal = ({ game, onClose }) => {
  const { propsData, loading, error } = usePlayerProps(game.id);
  const { predictions, predLoading, predError } = usePlayerPropsPredictions(game.id);
  const { predictions: backtestingData, loading: backtestingLoading } = useBacktestingAnalytics('all', 'all', true);
  
  // Add new state for AI predictions
  const [aiPredictions, setAiPredictions] = useState(null);

  // Normalize player name function (existing)
  const normalizePlayerName = (name) => {
    // Split the name and reverse if in "LastName FirstName" format
    const parts = name.split(' ');
    if (parts.length === 2) {
      // Check if name is in "LastName FirstName" format
      const [first, second] = parts;
      if (first.length > second.length) {
        return `${second} ${first}`;
      }
    }
    return name;
  };

  // Process backtesting data for players with name normalization
  const playerStats = useMemo(() => {
    if (!backtestingData?.length) {
      console.log('No backtesting data available');
      return new Map();
    }

    console.log('Processing backtesting data:', backtestingData);
    const statsMap = new Map();

    backtestingData.forEach(prediction => {
      if (!prediction.results?.players) return;

      // Process each player's results with normalized names
      Object.entries(prediction.results.players).forEach(([playerName, playerResults]) => {
        const normalizedName = normalizePlayerName(playerName);
        console.log(`Processing player: ${playerName} -> ${normalizedName}`);
        
        if (!statsMap.has(normalizedName)) {
          statsMap.set(normalizedName, {
            points: { correct: 0, total: 0, accuracy: 0 },
            rebounds: { correct: 0, total: 0, accuracy: 0 },
            assists: { correct: 0, total: 0, accuracy: 0 },
            threes: { correct: 0, total: 0, accuracy: 0 }
          });
        }

        const playerData = statsMap.get(normalizedName);

        // Process each stat type's results
        Object.entries(playerResults.correct || {}).forEach(([stat, isCorrect]) => {
          if (['points', 'rebounds', 'assists', 'threes'].includes(stat)) {
            playerData[stat].total++;
            if (isCorrect) {
              playerData[stat].correct++;
            }
            // Update accuracy
            playerData[stat].accuracy = (playerData[stat].correct / playerData[stat].total) * 100;
            console.log(`${normalizedName} ${stat}: ${playerData[stat].correct}/${playerData[stat].total} (${playerData[stat].accuracy.toFixed(1)}%)`);
          }
        });
      });
    });

    console.log('Final stats map:', Array.from(statsMap.entries()));
    return statsMap;
  }, [backtestingData]);

  // Fetch AI predictions on mount
  useEffect(() => {
    const fetchAiPredictions = async () => {
      try {
        const predRef = collection(db, 'nba_betPredictions');
        const q = query(predRef, where('gameId', '==', game.id));
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          setAiPredictions(snapshot.docs[0].data());
        }
      } catch (error) {
        console.error('Error fetching AI predictions:', error);
      }
    };
    
    fetchAiPredictions();
  }, [game.id]);

  const getAIPredictionMatch = (player, marketType, propLine) => {
    if (!aiPredictions?.playerPerformance?.playerPerformance?.predictions) return null;

    const aiPrediction = aiPredictions.playerPerformance.playerPerformance.predictions
      .find(p => p.playerName.toLowerCase() === player.toLowerCase());
    
    if (!aiPrediction) return null;

    let predictedValue;
    switch(marketType) {
      case 'points': predictedValue = parseFloat(aiPrediction.points); break;
      case 'assists': predictedValue = parseFloat(aiPrediction.assists); break;
      case 'rebounds': predictedValue = parseFloat(aiPrediction.rebounds); break;
      case 'threes': predictedValue = parseFloat(aiPrediction.threePointers); break;
      default: return null;
    }

    if (isNaN(predictedValue)) return null;

    const confidence = parseFloat(aiPrediction.confidence);
    if (confidence < 70) return null;

    // Check if AI prediction aligns with prop line
    const difference = predictedValue - propLine;
    if (Math.abs(difference) < 0.5) return null; // Ignore very close predictions

    return {
      predictedValue,
      confidence,
      difference,
      reasoning: aiPrediction.reasoning
    };
  };

  const markets = {
    points: 'Points',
    assists: 'Assists',
    rebounds: 'Rebounds',
    threes: 'Three Pointers'
  };

  // New helper function to get all prop lines for a player
  const getAllPropLines = (player, marketType, propsData) => {
    const standardMarket = propsData?.props?.draftkings?.markets[`player_${marketType}`]?.players[player];
    const alternateMarket = propsData?.props?.draftkings?.markets[`player_${marketType}_alternate`]?.players[player];
    
    let lines = [];
    if (standardMarket) {
      lines.push({
        ...standardMarket,
        isStandard: true
      });
    }
    if (alternateMarket) {
      lines.push({
        ...alternateMarket,
        isAlternate: true
      });
    }
    
    // Sort by point value
    return lines.sort((a, b) => a.point - b.point);
  };

  // Update PropCard Component
  const PropCard = ({ player, data, marketType, game, playerPrediction, aiMatch }) => {
    const [selectedLine, setSelectedLine] = useState(data);
    const [showAlternateLines, setShowAlternateLines] = useState(false);
    
    // Get all available lines for this player
    const allLines = getAllPropLines(player, marketType, propsData);

    // Group lines by type (Over/Under)
    const groupedLines = allLines.reduce((acc, line) => {
      const key = line.type.toLowerCase();
      if (!acc[key]) acc[key] = [];
      acc[key].push(line);
      return acc;
    }, {});

    const getMarketData = (type) => {
      switch(type) {
        case 'points': return playerPrediction?.seasonAverages?.points;
        case 'assists': return playerPrediction?.seasonAverages?.assists;
        case 'rebounds': return playerPrediction?.seasonAverages?.rebounds;
        case 'threes': return playerPrediction?.seasonAverages?.threes;
        default: return null;
      }
    };

    const getAnalysis = (type, line, lineType) => {
      const recentForm = playerPrediction?.recentForm?.[type];
      if (!recentForm?.lastGames) return null;

      // Calculate hits based on line type (over/under)
      const hits = recentForm.lastGames.filter(val => {
        if (lineType.toLowerCase() === 'over') {
          return val > line;
        } else {
          return val < line;
        }
      }).length;

      const calculatedHitRate = ((hits / recentForm.lastGames.length) * 100).toFixed(1);

      return {
        ...recentForm,
        hitRate: calculatedHitRate,
        hits,
        totalGames: recentForm.lastGames.length,
        lineType: lineType.toLowerCase() // Store the line type for reference
      };
    };

    const analysis = getAnalysis(marketType, selectedLine.point, selectedLine.type);

    return (
      <motion.div 
        className="bg-white/[0.03] rounded-lg p-4 hover:bg-white/[0.05] transition-colors duration-200"
        whileHover={{ scale: 1.01 }}
      >
        {/* Player Name and Basic Info */}
        <div className="flex justify-between items-start mb-3">
          <span className="text-white font-medium text-lg">{player}</span>
          <div className="flex items-center gap-2">
            <img src="/images/sportsbooks/draftkings.png" alt="DraftKings" className="h-4 w-auto"/>
            <span className="text-sm text-blue-400 font-medium">{selectedLine.price}</span>
          </div>
        </div>

        {/* Line Selection Explanation */}
        <div className="mb-3 px-3 py-2 bg-blue-500/10 rounded-lg">
          <p className="text-xs text-blue-400">
            Click a line below to view its analysis and hit rates
          </p>
        </div>

        {/* Lines Selection */}
        <div className="space-y-4">
          {/* Over Lines */}
          <div>
            <div className="text-xs text-gray-400 mb-2">Over Lines</div>
            <div className="flex flex-wrap gap-2">
              {groupedLines.over?.map((line, index) => (
                <button
                  key={`${line.type.toLowerCase()}-${line.point}-${line.isAlternate ? 'alt' : 'std'}`}
                  onClick={() => setSelectedLine(line)}
                  className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-all duration-200 ${
                    selectedLine === line 
                      ? 'bg-blue-500/20 border border-blue-500/30' 
                      : 'bg-gray-800/30 hover:bg-gray-800/50'
                  }`}
                >
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-white">
                      O {line.point}
                    </span>
                    {line.isAlternate && (
                      <span className="text-[10px] px-1.5 py-0.5 rounded-full bg-blue-500/20 text-blue-400">
                        Alt
                      </span>
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <span className={`text-sm ${
                      line.price >= 2.0 ? 'text-green-400' :
                      line.price >= 1.5 ? 'text-blue-400' : 'text-gray-400'
                    }`}>
                      {line.price}
                    </span>
                    <div className="flex items-center gap-1.5">
                      {line.link && (
                        <a
                          href={line.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center hover:opacity-80 transition-opacity"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <img src="/images/sportsbooks/draftkings.png" alt="Add to DraftKings Betslip" className="h-4 w-auto"/>
                        </a>
                      )}
                      <div 
                        className={`text-[10px] px-1.5 py-0.5 rounded bg-blue-500/20 text-blue-400 cursor-pointer hover:bg-blue-500/30 transition-colors ${
                          selectedLine === line ? 'bg-blue-500/40' : ''
                        }`}
                      >
                        {selectedLine === line ? 'Viewing Analysis' : 'See Analysis'}
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>

          {/* Under Lines */}
          <div>
            <div className="text-xs text-gray-400 mb-2">Under Lines</div>
            <div className="flex flex-wrap gap-2">
              {groupedLines.under?.map((line, index) => (
                <button
                  key={`${line.type.toLowerCase()}-${line.point}-${line.isAlternate ? 'alt' : 'std'}`}
                  onClick={() => setSelectedLine(line)}
                  className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-all duration-200 ${
                    selectedLine === line 
                      ? 'bg-blue-500/20 border border-blue-500/30' 
                      : 'bg-gray-800/30 hover:bg-gray-800/50'
                  }`}
                >
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-white">
                      U {line.point}
                    </span>
                    {line.isAlternate && (
                      <span className="text-[10px] px-1.5 py-0.5 rounded-full bg-blue-500/20 text-blue-400">
                        Alt
                      </span>
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <span className={`text-sm ${
                      line.price >= 2.0 ? 'text-green-400' :
                      line.price >= 1.5 ? 'text-blue-400' : 'text-gray-400'
                    }`}>
                      {line.price}
                    </span>
                    <div className="flex items-center gap-1.5">
                      {line.link && (
                        <a
                          href={line.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center hover:opacity-80 transition-opacity"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <img src="/images/sportsbooks/draftkings.png" alt="Add to DraftKings Betslip" className="h-4 w-auto"/>
                        </a>
                      )}
                      <div 
                        className={`text-[10px] px-1.5 py-0.5 rounded bg-blue-500/20 text-blue-400 cursor-pointer hover:bg-blue-500/30 transition-colors ${
                          selectedLine === line ? 'bg-blue-500/40' : ''
                        }`}
                      >
                        {selectedLine === line ? 'Viewing Analysis' : 'See Analysis'}
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Selected Line Analysis Header */}
        <div className="mt-4 mb-2 px-3 py-2 bg-white/[0.03] rounded-lg">
          <p className="text-sm text-white font-medium">
            Analysis for {selectedLine.type} {selectedLine.point}
          </p>
        </div>

        {/* Hit Rate Badge */}
        {analysis && (
          <div className="mt-3 flex items-center gap-2">
            <div className={`px-2 py-1 rounded-full text-xs font-medium ${
              parseFloat(analysis.hitRate) >= 70 ? 'bg-green-500/20 text-green-400' : 
              parseFloat(analysis.hitRate) >= 50 ? 'bg-yellow-500/20 text-yellow-400' : 
              'bg-red-500/20 text-red-400'
            }`}>
              {analysis.hitRate}% Hit Rate
            </div>
            <span className="text-xs text-gray-400">
              ({analysis.hits}/{analysis.totalGames} games {analysis.lineType} {selectedLine.point})
            </span>
          </div>
        )}

        {/* Stats Grid */}
        <div className="grid grid-cols-3 gap-4 mt-4">
          {/* Season Average */}
          <div className="flex flex-col">
            <span className="text-gray-400 text-xs">Season Avg</span>
            <span className="text-white font-medium">
              {getMarketData(marketType)?.toFixed(1) || '-'}
            </span>
          </div>
          
          {/* Recent Form */}
          <div className="flex flex-col">
            <span className="text-gray-400 text-xs">Recent Form</span>
            <div className="flex items-center gap-2">
              {analysis?.trend === 'increasing' ? (
                <TrendingUpIcon className="h-4 w-4 text-green-400" />
              ) : (
                <TrendingDownIcon className="h-4 w-4 text-red-400" />
              )}
              <span className="text-white font-medium">
                {analysis?.average || '-'}
              </span>
            </div>
          </div>
          
          {/* Games Played */}
          <div className="flex flex-col">
            <span className="text-gray-400 text-xs">Games</span>
            <span className="text-white font-medium">
              {playerPrediction?.seasonAverages?.gamesPlayed || '-'}
            </span>
          </div>
        </div>

        {/* Recent Games Chart */}
        {analysis?.lastGames && (
          <div className="mt-4 pt-4 border-t border-white/[0.05]">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm text-white font-medium">Recent Performance</span>
              <span className="text-xs text-gray-400">
                Last {analysis.lastGames.length} Games
              </span>
            </div>
            
            <div className="relative flex items-end justify-between h-16">
              {/* Trend line */}
              <svg className="absolute inset-0 h-full w-full" preserveAspectRatio="none" viewBox="0 0 100 100">
                <polyline
                  points={analysis.lastGames
                    .slice()
                    .reverse()
                    .map((val, idx) => {
                      const x = idx * 25;
                      const y = 100 - ((val / Math.max(...analysis.lastGames)) * 100);
                      return `${x},${y}`;
                    })
                    .join(' ')}
                  fill="none"
                  stroke="rgba(59, 130, 246, 0.5)"
                  strokeWidth="2"
                  className="transition-all duration-200"
                />
              </svg>
              
              {/* Line markers */}
              <div className="absolute inset-0 flex items-center">
                <div 
                  className="w-full border-t border-dashed border-blue-500/30"
                  style={{ marginTop: `${100 - ((selectedLine.point / Math.max(...analysis.lastGames)) * 100)}%` }}
                />
              </div>
              
              {/* Bars */}
              {analysis.lastGames.slice().reverse().map((val, idx) => {
                const isHit = analysis.lineType === 'over' ? val > selectedLine.point : val < selectedLine.point;
                return (
                  <div key={idx} className="relative flex flex-col items-center w-full z-10">
                    <div 
                      style={{ height: `${(val / Math.max(...analysis.lastGames)) * 100}%` }}
                      className={`w-2 ${
                        isHit ? 'bg-green-500/50' : 'bg-red-500/50'
                      } rounded-none transition-all duration-200`}
                    />
                    <div className="absolute bottom-0 w-full h-full flex items-center justify-center">
                      <div 
                        className={`w-2 h-0.5 ${
                          isHit ? 'bg-green-500' : 'bg-red-500'
                        }`}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            
            {/* Values display */}
            <div className="flex justify-between mt-2">
              {analysis.lastGames.slice().reverse().map((val, idx) => {
                const isHit = analysis.lineType === 'over' ? val > selectedLine.point : val < selectedLine.point;
                return (
                  <span 
                    key={idx} 
                    className={`text-xs ${
                      isHit ? 'text-green-400' : 'text-red-400'
                    }`}
                  >
                    {val}
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </motion.div>
    );
  };

  const renderAIPredictions = () => {
    console.log('AI Predictions Data:', aiPredictions);

    if (!aiPredictions) {
      return (
        <div className="text-gray-400 text-center py-4">
          Loading AI predictions...
        </div>
      );
    }

    const predictions = aiPredictions?.playerPerformance?.playerPerformance?.predictions || [];
    console.log('Processed Predictions:', predictions);

    if (predictions.length === 0) {
      return (
        <div className="text-gray-400 text-center py-4">
          No AI predictions available
        </div>
      );
    }

    // Helper function to find matching prop data
    const findMatchingPropData = (playerName) => {
      const normalizedName = normalizePlayerName(playerName);
      console.log('Looking for props for:', normalizedName);
      
      // Try different name formats
      const markets = propsData?.props?.draftkings?.markets;
      const formats = [
        playerName,                          // Original format
        normalizedName,                      // Normalized format
        playerName.split(' ').reverse().join(' '), // Reversed format
        normalizedName.toLowerCase(),        // Lowercase normalized
        playerName.toLowerCase()             // Lowercase original
      ];

      const result = {
        points: [],
        rebounds: [],
        assists: []
      };

      ['points', 'rebounds', 'assists'].forEach(stat => {
        // Check both standard and alternate markets
        const standardMarket = markets?.[`player_${stat}`]?.players;
        const alternateMarket = markets?.[`player_${stat}_alternate`]?.players;
        
        if (!standardMarket && !alternateMarket) return;

        let foundLines = [];
        
        // Helper to collect lines from a market
        const collectLines = (market) => {
          for (const format of formats) {
            const found = Object.entries(market).find(([name, _]) => 
              name.toLowerCase() === format.toLowerCase()
            );
            if (found) {
              // Only collect Over lines
              const line = found[1];
              if (line.type.toLowerCase() === 'over') {
                // Check if this line point value already exists
                const existingLine = foundLines.find(l => l.point === line.point);
                if (!existingLine) {
                  foundLines.push({
                    ...line,
                    isAlternate: market === alternateMarket
                  });
                }
              }
              break;
            }
          }
        };

        // Collect lines from both markets
        if (standardMarket) collectLines(standardMarket);
        if (alternateMarket) collectLines(alternateMarket);

        // Sort lines by point value (ascending)
        foundLines.sort((a, b) => a.point - b.point);
        result[stat] = foundLines;
      });

      console.log('Found prop data:', result);
      return result;
    };

    // Helper function to determine if prediction is relevant to the prop line
    const isPredictionRelevant = (prediction, propLines) => {
      if (!prediction || !propLines?.length) return false;
      
      const predValue = parseFloat(prediction);
      
      // Create a Set to track unique point values
      const seenPoints = new Set();
      
      // Find all valid lines where prediction is higher
      const validLines = propLines.filter(line => {
        const lineValue = parseFloat(line.point);
        const diff = predValue - lineValue;
        
        // Skip if we've already seen this point value
        if (seenPoints.has(lineValue)) return false;
        seenPoints.add(lineValue);
        
        // We want the prediction to be higher by a reasonable margin (1 point minimum)
        return diff >= 1;
      });

      // Sort by point value descending to get the best value lines first
      validLines.sort((a, b) => b.point - a.point);
      
      // Return all valid lines
      return validLines;
    };

    // Helper function to render the best matching lines
    const renderBestLine = (stat, prediction, lines) => {
      const validLines = isPredictionRelevant(prediction, lines);
      if (!validLines?.length) return null;

      const predValue = parseFloat(prediction);

      return (
        <div className="mt-2 space-y-2">
          {validLines.map(line => {
            const lineValue = parseFloat(line.point);
            const margin = (predValue - lineValue).toFixed(1);
            
            return (
              <a 
                key={`${line.point}-${line.price}`}
                href={line.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-1 px-2 py-1 rounded bg-blue-500/20 text-blue-400 hover:bg-blue-500/30 w-fit"
              >
                <img src="/images/sportsbooks/draftkings.png" alt="DraftKings" className="h-3 w-auto"/>
                <span className="text-xs font-medium">
                  Over {line.point} ({line.price})
                  <span className="ml-1 text-green-400">+{margin}</span>
                </span>
                {line.isAlternate && (
                  <span className="ml-1 text-[10px] px-1.5 py-0.5 rounded-full bg-blue-500/20">
                    Alt
                  </span>
                )}
              </a>
            );
          })}
        </div>
      );
    };

    // Add a helper function to format player name
    const formatPlayerName = (name) => {
      const parts = name.split(' ');
      if (parts.length === 2) {
        const [last, first] = parts;
        if (first.length < last.length) {  // This is likely a "Last First" format
          return `${first} ${last}`;
        }
      }
      return name; // Return as is if not in expected format or already in correct format
    };

    return (
      <div className="space-y-4">
        {predictions.map((player, index) => {
          const propData = findMatchingPropData(player.playerName);
          const normalizedName = normalizePlayerName(player.playerName);
          const displayName = formatPlayerName(player.playerName);
          
          // Get backtesting stats for each stat type
          const backtestingPointsStats = playerStats.get(normalizedName)?.points;
          const backtestingReboundsStats = playerStats.get(normalizedName)?.rebounds;
          const backtestingAssistsStats = playerStats.get(normalizedName)?.assists;

          // Helper function to render stat accuracy
          const renderStatAccuracy = (stats) => {
            if (!stats || stats.total === 0 || stats.accuracy < 70) return null;
            return (
              <div className="flex items-center gap-1.5 mt-1">
                <div className="w-1.5 h-1.5 rounded-full bg-green-500" />
                <span className="text-[10px] text-gray-400">A.I. Success:</span>
                <span className="text-[10px] text-green-400 font-medium">
                  {stats.accuracy.toFixed(1)}%
                </span>
              </div>
            );
          };

          return (
            <motion.div 
              key={`${player.playerName}-${index}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white/[0.03] rounded-lg p-4"
            >
              <div className="flex flex-col space-y-4">
                {/* Player name and confidence */}
                <div className="flex justify-between items-start">
                  <span className="text-lg font-medium text-white">
                    {displayName}
                  </span>
                  <span className="px-2 py-1 rounded bg-blue-500/20 text-blue-400 text-sm">
                    {player.confidence}% Confidence
                  </span>
                </div>

                {/* Stats grid */}
                <div className="grid grid-cols-3 gap-4">
                  {/* Points */}
                  <div className="bg-gray-800/30 p-3 rounded-lg">
                    <div className="text-xs text-gray-400 mb-1">Points</div>
                    <div className="text-lg font-medium text-white">{player.points}</div>
                    {renderStatAccuracy(backtestingPointsStats)}
                    {renderBestLine('points', player.points, propData.points)}
                  </div>

                  {/* Rebounds */}
                  <div className="bg-gray-800/30 p-3 rounded-lg">
                    <div className="text-xs text-gray-400 mb-1">Rebounds</div>
                    <div className="text-lg font-medium text-white">{player.rebounds}</div>
                    {renderStatAccuracy(backtestingReboundsStats)}
                    {renderBestLine('rebounds', player.rebounds, propData.rebounds)}
                  </div>

                  {/* Assists */}
                  <div className="bg-gray-800/30 p-3 rounded-lg">
                    <div className="text-xs text-gray-400 mb-1">Assists</div>
                    <div className="text-lg font-medium text-white">{player.assists}</div>
                    {renderStatAccuracy(backtestingAssistsStats)}
                    {renderBestLine('assists', player.assists, propData.assists)}
                  </div>
                </div>

                {/* Reasoning */}
                <div className="text-sm text-gray-400 leading-relaxed">
                  {player.reasoning}
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    );
  };

  const renderPropTable = (marketType) => {
    if (!propsData?.props?.draftkings?.markets?.[`player_${marketType}`]?.players) {
      return (
        <div className="text-gray-400 text-center py-4">
          No {markets[marketType]} props available
        </div>
      );
    }

    const marketData = propsData.props.draftkings.markets[`player_${marketType}`].players;

    // Convert to array and add analysis data for sorting
    const playersWithAnalysis = Object.entries(marketData).map(([player, data]) => {
      const playerPrediction = predictions?.players?.find(p => 
        p.name.toLowerCase() === player.toLowerCase()
      );
      const recentForm = playerPrediction?.recentForm?.[marketType];
      const hitRate = recentForm?.lastGames 
        ? (recentForm.lastGames.filter(val => val > data.point).length / recentForm.lastGames.length * 100).toFixed(1)
        : 0;

      return {
        player,
        data,
        hitRate: parseFloat(hitRate)
      };
    });

    // Sort by hit rate descending
    playersWithAnalysis.sort((a, b) => b.hitRate - a.hitRate);

    return (
      <div className="space-y-3">
        {playersWithAnalysis.map(({ player, data }) => {
          const playerPrediction = predictions?.players?.find(p => 
            p.name.toLowerCase() === player.toLowerCase()
          );
          const aiMatch = getAIPredictionMatch(player, marketType, data.point);
          
          return (
            <PropCard
              key={player}
              player={player}
              data={data}
              marketType={marketType}
              game={game}
              playerPrediction={playerPrediction}
              aiMatch={aiMatch}
            />
          );
        })}
      </div>
    );
  };

  if (loading) {
    return (
      <motion.div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
        <div className="text-white">Loading props...</div>
      </motion.div>
    );
  }

  return (
    <motion.div 
      className="fixed inset-0 z-50 flex items-center justify-center p-2 sm:p-4 bg-black/50 backdrop-blur-sm"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.div 
        className="bg-[#0B1120] rounded-xl w-full max-w-2xl max-h-[90vh] overflow-hidden"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
      >
        <div className="p-3 sm:p-4 border-b border-white/[0.05]">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold tracking-tight">
                <span className="text-gray-100">Strategix</span>
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 font-bold">Picks</span>
              </span>
              <span className="text-gray-400 mx-2">|</span>
              <h2 className="text-lg sm:text-xl font-bold text-white">Player Props</h2>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              ✕
            </button>
          </div>
        </div>

        {error ? (
          <div className="p-4 text-red-400 text-center">{error}</div>
        ) : (
          <Tab.Group>
            <div className="px-3 sm:px-4 pt-3 sm:pt-4 space-y-2">
              {/* Main Tabs */}
              <div className="grid grid-cols-2 gap-1.5 sm:gap-2">
                {/* A.I. Predictions Tab */}
                <Tab
                  className={({ selected }) =>
                    `w-full px-3 sm:px-4 py-2 sm:py-2.5 text-xs sm:text-sm font-medium rounded-lg transition-all duration-200
                    ${selected 
                      ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg shadow-blue-500/20' 
                      : 'text-gray-400 hover:text-white hover:bg-white/[0.05] border border-white/[0.05]'
                    }`
                  }
                >
                  <div className="flex items-center justify-center gap-1.5 sm:gap-2">
                    <span>🤖</span>
                    <span className="font-semibold">A.I. Key Player Predictions</span>
                  </div>
                </Tab>

                {/* Player Props Info Tab */}
                <Tab
                  className={({ selected }) =>
                    `w-full px-3 sm:px-4 py-2 sm:py-2.5 text-xs sm:text-sm font-medium rounded-lg transition-all duration-200
                    ${selected 
                      ? 'bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-lg shadow-blue-500/20' 
                      : 'text-gray-400 hover:text-white hover:bg-white/[0.05] border border-white/[0.05]'
                    }`
                  }
                >
                  <div className="flex items-center justify-center gap-1.5 sm:gap-2">
                    <span>📊</span>
                    <span className="font-semibold">Player Prop Lines Analysis</span>
                  </div>
                </Tab>
              </div>
            </div>

            <Tab.Panels>
              {/* A.I. Predictions Panel */}
              <Tab.Panel className="p-3 sm:p-4 overflow-y-auto max-h-[calc(90vh-12rem)]">
                {renderAIPredictions()}
              </Tab.Panel>

              {/* Player Props Info Panel */}
              <Tab.Panel>
                <Tab.Group>
                  {/* Disclaimer Info Bar */}
                  <div className="px-3 sm:px-4 pt-2 sm:pt-3 pb-2">
                    <div className="flex items-center gap-2 p-2 sm:p-2.5 rounded-lg bg-yellow-500/10 border border-yellow-500/20">
                      <span className="text-yellow-400/90">⚠️</span>
                      <p className="text-[10px] sm:text-xs text-yellow-200/90">
                        These lines are based on statistical calculations only, not A.I. predictions. Please review carefully and bet responsibly.
                      </p>
                    </div>
                  </div>

                  {/* Sub-tabs for different prop types */}
                  <Tab.List className="flex space-x-1 px-3 sm:px-4 pt-2 overflow-x-auto scrollbar-none">
                    {Object.entries(markets).map(([key, label]) => (
                      <Tab
                        key={key}
                        className={({ selected }) =>
                          `flex-1 whitespace-nowrap px-3 sm:px-4 py-1 sm:py-1.5 text-xs sm:text-sm font-medium rounded-lg transition-all duration-200
                          ${selected 
                            ? 'bg-blue-500 text-white shadow-lg shadow-blue-500/20' 
                            : 'text-gray-400 hover:text-white hover:bg-white/[0.05]'
                          }`
                        }
                      >
                        {label}
                      </Tab>
                    ))}
                  </Tab.List>

                  <Tab.Panels className="p-3 sm:p-4 overflow-y-auto max-h-[calc(90vh-16rem)]">
                    {Object.keys(markets).map((market) => (
                      <Tab.Panel key={market}>
                        {renderPropTable(market)}
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        )}
      </motion.div>
    </motion.div>
  );
};

export default PlayerPropsModal; 