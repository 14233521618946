import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase';

export const usePlayerPropsPredictions = (gameId) => {
  const [predictions, setPredictions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPredictions = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Query for predictions using gameId
        const predictionsRef = collection(db, 'nba_propsPredictions');
        const q = query(predictionsRef, where('gameId', '==', parseInt(gameId)));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const predDoc = querySnapshot.docs[0];
          console.log('Found predictions for game:', gameId, predDoc.data());
          setPredictions(predDoc.data());
        } else {
          console.log('No predictions found for game:', gameId);
          setError('No predictions available');
        }
      } catch (err) {
        console.error('Error fetching predictions:', err);
        setError('Failed to load predictions');
      } finally {
        setLoading(false);
      }
    };

    if (gameId) {
      fetchPredictions();
    }
  }, [gameId]);

  return { predictions, loading, error };
}; 