import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';

export const useNBAPlayerStats = (teamId) => {
  const [playerStats, setPlayerStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      if (!teamId) {
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching NBA player stats for teamId:', teamId);

        // 1. Get completed games for this team
        const gamesRef = collection(db, 'nba_games');
        const gamesSnapshot = await getDocs(query(gamesRef));
        
        const completedGames = gamesSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(game => 
            (game.status?.short === 'FT' || game.status?.long === 'Game Finished') &&
            (game.teams.home.id === Number(teamId) || game.teams.away.id === Number(teamId))
          )
          .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date, newest first
          .slice(0, 10); // Get only last 10 games

        console.log('Found completed games:', completedGames.length);

        // 2. Get player statistics for these games
        const statsRef = collection(db, 'nba_playerStatistics');
        const statsPromises = completedGames.map(async game => {
          const statsQuery = query(
            statsRef,
            where('gameId', '==', game.id)
          );
          
          const statsSnapshot = await getDocs(statsQuery);
          const players = statsSnapshot.docs
            .map(doc => doc.data())
            .filter(stat => stat.teamId === Number(teamId));

          return {
            gameId: game.id,
            gameDate: game.date,
            opponent: game.teams.home.id === Number(teamId) 
              ? game.teams.away 
              : game.teams.home,
            players
          };
        });

        const statsResults = await Promise.all(statsPromises);
        console.log('Final processed stats:', statsResults);
        setPlayerStats(statsResults);
      } catch (err) {
        console.error('Error fetching NBA player stats:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [teamId]);

  return { playerStats, loading, error };
}; 