import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useAuth } from '../../contexts/AuthContext';
import ChatWindow from '../ChatWindow/ChatWindow';
import GameOverview from '../GameOverview/GameOverview';
import { Link } from 'react-router-dom';
import { useSport } from '../../contexts/SportContext';
import NFLGameCardPredictions from '../GameOverview/sports/NFL/NFLGameCardPredictions';
import NBAGameCardPredictions from '../GameOverview/sports/NBA/NBAGameCardPredictions';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useSpring, animated } from '@react-spring/web';
import PlayerPropsModal from '../PlayerProps/PlayerPropsModal';

const GameCard = ({ game }) => {
  const { activeSport } = useSport();
  const { user } = useAuth();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isOverviewOpen, setIsOverviewOpen] = useState(false);
  const [isPropsOpen, setPropsOpen] = useState(false);
  const [predictions, setPredictions] = useState(null);
  const [predictionData, setPredictionData] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const { teams } = game;
  const gameDate = new Date(activeSport === 'NBA' ? game.date : game.date.timestamp * 1000);
  
  // React Spring animations
  const cardSpring = useSpring({
    from: { opacity: 0, transform: 'scale(0.95)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 300, friction: 20 }
  });

  useEffect(() => {
    if (!user) return;

    const subscriptionRef = collection(db, 'customers', user.uid, 'subscriptions');
    const unsubscribe = onSnapshot(subscriptionRef, (snapshot) => {
      const activeSub = snapshot.docs.find(doc => doc.data().status === 'active');
      if (activeSub) {
        setSubscription(activeSub.data());
      }
    });

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    const fetchPredictions = async () => {
      try {
        // Fetch bet predictions
        const betCollectionName = activeSport === 'NBA' ? 'nba_betPredictions' : 'betPredictions';
        const predictionRef = doc(db, betCollectionName, `ai_${game.id}`);
        const predictionDoc = await getDoc(predictionRef);
        
        // Fetch prediction data (with odds)
        const dataCollectionName = activeSport === 'NBA' ? 'nba_predictionData' : 'predictionData';
        const predictionDataRef = doc(db, dataCollectionName, `pred_${game.id}`);
        const predictionDataDoc = await getDoc(predictionDataRef);
        
        if (predictionDoc.exists()) {
          setPredictions(predictionDoc.data());
        }
        
        if (predictionDataDoc.exists()) {
          setPredictionData(predictionDataDoc.data());
        }
      } catch (error) {
        console.error('Error fetching predictions:', error);
      }
    };

    fetchPredictions();
  }, [game.id, activeSport]);

  const handleSubscriptionFeatureClick = () => {
    if (!user) {
      alert('Please log in to access this feature');
      return false;
    }
    if (!subscription?.status === 'active') {
      alert('Premium subscription required to access this feature');
      return false;
    }
    return true;
  };

  const renderPredictions = () => {
    if (activeSport === 'NBA') {
      return <NBAGameCardPredictions 
        predictions={predictions} 
        predictionData={predictionData}
      />;
    }
    return <NFLGameCardPredictions predictions={predictions} />;
  };

  return (
    <animated.div style={cardSpring}>
      <motion.div 
        className="relative bg-[#0B1120]/90 backdrop-blur-xl rounded-xl overflow-hidden isolate w-full"
        whileHover={{ scale: 1.01 }}
        transition={{ type: "spring", stiffness: 300, damping: 15 }}
      >
        {/* Date Header */}
        <div className="px-4 py-2 border-b border-white/[0.05]">
          <span className="text-xs text-gray-400/90 font-medium tracking-wider uppercase">
            {gameDate.toLocaleDateString('en-US', { 
              weekday: 'short',
              month: 'short', 
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit'
            })}
          </span>
        </div>

        {/* Teams Section */}
        <div className="px-4 py-6">
          <div className="grid grid-cols-[1fr_auto_1fr] items-center justify-center gap-6">
            {/* Away Team */}
            <div className="relative flex-shrink-0 flex justify-center">
              <div className="w-24 h-24 sm:w-32 sm:h-32 rounded-2xl bg-white/[0.03] p-4 sm:p-5 border border-white/[0.08] flex items-center justify-center">
                <img 
                  src={teams.away.logo} 
                  alt={teams.away.name}
                  className={`w-full h-full object-contain ${activeSport === 'NBA' ? 'rounded-full' : ''}`}
                />
              </div>
            </div>

            {/* VS Badge */}
            <div className="px-3 py-1.5 rounded-md bg-white/[0.03] border border-white/[0.08]">
              <span className="text-sm font-medium text-gray-400">
                VS
              </span>
            </div>

            {/* Home Team */}
            <div className="relative flex-shrink-0 flex justify-center">
              <div className="w-24 h-24 sm:w-32 sm:h-32 rounded-2xl bg-white/[0.03] p-4 sm:p-5 border border-white/[0.08] flex items-center justify-center">
                <img 
                  src={teams.home.logo} 
                  alt={teams.home.name}
                  className={`w-full h-full object-contain ${activeSport === 'NBA' ? 'rounded-full' : ''}`}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Predictions Section */}
        <div>
          {renderPredictions()}
        </div>

        {/* Actions Footer */}
        <div className="grid grid-cols-3 border-t border-white/[0.05]">
          {/* Chat Button */}
          <button 
            onClick={() => setIsChatOpen(true)}
            className={`py-2.5 flex flex-col items-center justify-center ${user && subscription?.status === 'active' ? 'text-blue-400' : 'text-gray-500'}`}
          >
            <span className="text-xl">💬</span>
            <span className="text-[9px] sm:text-[11px] font-medium tracking-wider uppercase mt-0.5">
              AI Expert
            </span>
            {(!user || subscription?.status !== 'active') && <span className="absolute top-2 right-2">🔒</span>}
          </button>

          {/* Stats Button */}
          <button 
            onClick={() => setIsOverviewOpen(true)}
            className={`py-2.5 flex flex-col items-center justify-center border-l border-white/[0.05] ${user && subscription?.status === 'active' ? 'text-gray-400' : 'text-gray-500'}`}
          >
            <span className="text-xl">📊</span>
            <span className="text-[9px] sm:text-[11px] font-medium tracking-wider uppercase mt-0.5">
              Game Stats
            </span>
            {(!user || subscription?.status !== 'active') && <span className="absolute top-2 right-2">🔒</span>}
          </button>

          {/* Player Props Button */}
          <button 
            onClick={() => {
              if (handleSubscriptionFeatureClick()) {
                setPropsOpen(true);
              }
            }}
            className={`py-2.5 flex flex-col items-center justify-center border-l border-white/[0.05] ${user && subscription?.status === 'active' ? 'text-gray-400' : 'text-gray-500'}`}
          >
            <span className="text-xl">🎯</span>
            <span className="text-[9px] sm:text-[11px] font-medium tracking-wider uppercase mt-0.5">
              Player Props
            </span>
            {(!user || subscription?.status !== 'active') && <span className="absolute top-2 right-2">🔒</span>}
          </button>
        </div>

        {/* Premium CTA */}
        {(!user || subscription?.status !== 'active') && (
          <motion.div 
            className="bg-gradient-to-br from-blue-500/[0.08] to-purple-500/[0.08] border-t border-blue-500/20 p-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
          >
            <div className="text-center space-y-3">
              <motion.p 
                className="text-sm font-bold text-blue-400 font-['Space_Grotesk'] tracking-wide drop-shadow-[0_1px_2px_rgba(0,0,0,0.5)]"
                animate={{ 
                  scale: [1, 1.05, 1],
                  opacity: [0.8, 1, 0.8]
                }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                <span className="mr-2">✨</span>
                Unlock Premium Features
                <span className="ml-2">✨</span>
              </motion.p>
              <p className="text-xs text-gray-400/90 font-['Inter'] tracking-wide">
                Get A.I. Expert analysis and advanced Player Props predictions - Only $4.99/mo
              </p>
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Link 
                  to="/account" 
                  className="inline-block bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white text-sm font-bold px-6 py-2 rounded-full transition-colors duration-200 font-['Space_Grotesk'] tracking-wide drop-shadow-[0_2px_4px_rgba(0,0,0,0.3)]"
                >
                  Upgrade to Premium
                </Link>
              </motion.div>
            </div>
          </motion.div>
        )}

        {/* Move modals outside the card using portals */}
        {isChatOpen && user && subscription?.status === 'active' && createPortal(
          <ChatWindow game={game} onClose={() => setIsChatOpen(false)} />,
          document.getElementById('modal-root')
        )}
        
        {isOverviewOpen && user && subscription?.status === 'active' && createPortal(
          <GameOverview game={game} predictions={predictions} onClose={() => setIsOverviewOpen(false)} />,
          document.getElementById('modal-root')
        )}
        
        {isPropsOpen && user && subscription?.status === 'active' && createPortal(
          <PlayerPropsModal game={game} onClose={() => setPropsOpen(false)} />,
          document.getElementById('modal-root')
        )}
      </motion.div>
    </animated.div>
  );
};

export default GameCard;