import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase';

export const usePlayerProps = (gameId) => {
  const [propsData, setPropsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlayerProps = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Query for documents that start with props_{gameId}_
        const propsRef = collection(db, 'nba_playerProps');
        const q = query(propsRef, where('gameId', '==', parseInt(gameId)));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          // Get the first matching document
          const propsDoc = querySnapshot.docs[0];
          console.log('Props data found:', propsDoc.data());
          setPropsData(propsDoc.data());
        } else {
          console.log('No props data found for game:', gameId);
          setError('No props data available for this game');
        }
      } catch (err) {
        console.error('Error fetching player props:', err);
        setError('Failed to load player props');
      } finally {
        setLoading(false);
      }
    };

    if (gameId) {
      fetchPlayerProps();
    }
  }, [gameId]);

  return { propsData, loading, error };
}; 