import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { collection, query, getDocs, where, orderBy, limit } from 'firebase/firestore';
import { db } from '../services/firebase';
import { FireIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const TopPicks = () => {
  const [todaysGames, setTodaysGames] = useState([]);
  const [currentPredictions, setCurrentPredictions] = useState([]);
  const [backtestingData, setBacktestingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data in sequence: odds -> predictions -> backtesting
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // 1. Get today's games from nba_odds (collection is always current)
        const oddsRef = collection(db, 'nba_odds');
        const oddsSnapshot = await getDocs(oddsRef);
        const games = oddsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Today\'s games:', games);
        setTodaysGames(games);

        if (games.length === 0) {
          console.log('No games found for today');
          setLoading(false);
          return;
        }

        // 2. Get predictions for these games
        const gameIds = games.map(game => game.gameId);
        console.log('Game IDs to fetch:', gameIds);

        const predictions = [];
        for (const gameId of gameIds) {
          const predRef = collection(db, 'nba_betPredictions');
          // Look for predictions with matching gameId
          const predQuery = query(predRef, where('gameId', '==', gameId));
          const predSnapshot = await getDocs(predQuery);
          const gamePredictions = predSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          predictions.push(...gamePredictions);
        }
        console.log('Current predictions:', predictions);
        setCurrentPredictions(predictions);

        // 3. Get historical backtesting data
        const backtestingRef = collection(db, 'nba_backtesting');
        const backtestingQuery = query(
          backtestingRef,
          orderBy('gameDate', 'desc'),
          limit(1000)
        );
        const backtestingSnapshot = await getDocs(backtestingQuery);
        const backtestingData = backtestingSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Backtesting data count:', backtestingData.length);
        setBacktestingData(backtestingData);

        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load predictions. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const topPicks = useMemo(() => {
    console.log('Computing top picks with:', {
      gamesCount: todaysGames.length,
      predictionsCount: currentPredictions.length,
      backtestingCount: backtestingData.length
    });

    if (!todaysGames.length || !currentPredictions.length || !backtestingData.length) {
      console.log('Missing required data');
      return null;
    }

    // Process historical performance
    const teamPerformance = new Map();
    
    // First, get all teamIds from today's games
    const todaysTeamIds = new Set();
    todaysGames.forEach(game => {
      if (game.teams?.home?.id) todaysTeamIds.add(game.teams.home.id);
      if (game.teams?.away?.id) todaysTeamIds.add(game.teams.away.id);
    });
    console.log('Today\'s team IDs:', Array.from(todaysTeamIds));

    // Process backtesting data for these teams
    backtestingData.forEach(record => {
      // Skip if no teams or results
      if (!record.teams?.home?.id || !record.teams?.away?.id || !record.results) return;

      // Only process records for teams playing today
      if (!todaysTeamIds.has(record.teams.home.id) && !todaysTeamIds.has(record.teams.away.id)) return;

      // Process game winner predictions
      if (record.results.gameWinner) {
        const predictedTeam = record.results.gameWinner.prediction;
        const actualTeam = record.results.gameWinner.actual;
        const isCorrect = record.results.gameWinner.correct;

        [record.teams.home.name, record.teams.away.name].forEach(teamName => {
          if (!teamPerformance.has(teamName)) {
            teamPerformance.set(teamName, {
              gameWinner: { correct: 0, total: 0 },
              totalPoints: { correct: 0, total: 0 }
            });
          }

          const stats = teamPerformance.get(teamName);
          if (teamName === predictedTeam) {
            stats.gameWinner.total++;
            if (isCorrect) stats.gameWinner.correct++;
          }
        });
      }

      // Process total points predictions
      if (record.results.totalPoints?.correct !== undefined) {
        [record.teams.home.name, record.teams.away.name].forEach(teamName => {
          if (!teamPerformance.has(teamName)) {
            teamPerformance.set(teamName, {
              gameWinner: { correct: 0, total: 0 },
              totalPoints: { correct: 0, total: 0 }
            });
          }
          const stats = teamPerformance.get(teamName);
          stats.totalPoints.total++;
          if (record.results.totalPoints.correct) {
            stats.totalPoints.correct++;
          }
        });
      }
    });

    // Get top 5 teams for game winners
    const topTeams = Array.from(teamPerformance.entries())
      .filter(([_, stats]) => stats.gameWinner.total >= 5) // Lower minimum threshold for testing
      .map(([team, stats]) => ({
        team,
        accuracy: (stats.gameWinner.correct / stats.gameWinner.total) * 100
      }))
      .sort((a, b) => b.accuracy - a.accuracy)
      .slice(0, 5);

    console.log('Team performance data:', Object.fromEntries(teamPerformance));
    console.log('Top performing teams:', topTeams);

    // Process player stats only for players in today's games
    const playerPerformance = new Map();
    const todaysPlayers = new Set();
    
    // Get all players from today's predictions
    currentPredictions.forEach(prediction => {
      // Players are nested in playerPerformance.playerPerformance.predictions
      if (prediction.playerPerformance?.playerPerformance?.predictions) {
        prediction.playerPerformance.playerPerformance.predictions.forEach(player => {
          if (player.playerName) {
            todaysPlayers.add(player.playerName);
          }
        });
      }
    });
    console.log('Today\'s players:', Array.from(todaysPlayers));

    // Process backtesting data for these players
    backtestingData.forEach(record => {
      if (!record.results?.players) return;

      // Debug log for player names in backtesting
      console.log('Backtesting record players:', Object.keys(record.results.players));

      Object.entries(record.results.players).forEach(([playerName, data]) => {
        // Try to find a matching player from today's players
        const matchingPlayer = Array.from(todaysPlayers).find(todayPlayer => {
          // Try different name formats
          const normalizedBacktestName = playerName.toLowerCase().replace(/\s+/g, '');
          const normalizedTodayName = todayPlayer.toLowerCase().replace(/\s+/g, '');
          
          // Try direct match
          if (normalizedBacktestName === normalizedTodayName) return true;
          
          // Try reversing the backtesting name (which is in "LastName FirstName" format)
          const [lastName, firstName] = playerName.split(' ');
          if (firstName && lastName) {
            const reversedName = `${firstName} ${lastName}`.toLowerCase().replace(/\s+/g, '');
            if (reversedName === normalizedTodayName) return true;
          }
          
          return false;
        });

        if (matchingPlayer) {
          console.log('Found matching player:', { backtestName: playerName, todayName: matchingPlayer });
        }

        // Only process if we found a matching player
        if (!matchingPlayer) return;

        if (!playerPerformance.has(matchingPlayer)) {
          playerPerformance.set(matchingPlayer, {
            points: { correct: 0, total: 0 },
            rebounds: { correct: 0, total: 0 },
            assists: { correct: 0, total: 0 }
          });
        }

        const stats = playerPerformance.get(matchingPlayer);
        if (data.correct) {
          ['points', 'rebounds', 'assists'].forEach(stat => {
            if (data.correct[stat] !== undefined) {
              stats[stat].total++;
              if (data.correct[stat] === true) {
                stats[stat].correct++;
              }
            }
          });
        }
      });
    });

    // Get top 5 players for each stat
    const getTopPlayersForStat = (stat) => {
      return Array.from(playerPerformance.entries())
        .filter(([_, stats]) => stats[stat].total >= 3) // Lower minimum threshold for testing
        .map(([player, stats]) => ({
          player,
          accuracy: (stats[stat].correct / stats[stat].total) * 100
        }))
        .sort((a, b) => b.accuracy - a.accuracy)
        .slice(0, 5);
    };

    const topPlayersPoints = getTopPlayersForStat('points');
    const topPlayersRebounds = getTopPlayersForStat('rebounds');
    const topPlayersAssists = getTopPlayersForStat('assists');

    console.log('Player performance data:', Object.fromEntries(playerPerformance));
    console.log('Top players:', {
      points: topPlayersPoints,
      rebounds: topPlayersRebounds,
      assists: topPlayersAssists
    });

    // Filter today's predictions
    const filteredPicks = {
      playerProps: []
    };

    // Process each current prediction
    currentPredictions.forEach(prediction => {
      // Player Props picks
      if (prediction.playerPerformance?.playerPerformance?.predictions) {
        prediction.playerPerformance.playerPerformance.predictions.forEach(player => {
          if (!player.playerName) return;

          // Find the game this prediction belongs to
          const game = todaysGames.find(g => g.gameId === prediction.gameId);
          if (!game) return;

          // Get team names from the game data
          const teamName = game.teams?.home?.name || game.teams?.away?.name || 'Unknown Team';

          const qualifiedProps = [];

          // Check each stat type
          ['points', 'rebounds', 'assists'].forEach(stat => {
            const topPlayersForStat = stat === 'points' ? topPlayersPoints :
                                    stat === 'rebounds' ? topPlayersRebounds :
                                    topPlayersAssists;
            
            const playerRanking = topPlayersForStat.find(p => p.player === player.playerName);
            // Only add props with 70% or higher success rate
            if (playerRanking && player[stat] && playerRanking.accuracy >= 70) {
              qualifiedProps.push({
                stat,
                value: player[stat],
                accuracy: playerRanking.accuracy,
                confidence: player.confidence || 0
              });
            }
          });

          if (qualifiedProps.length > 0) {
            filteredPicks.playerProps.push({
              player: player.playerName,
              team: teamName,
              props: qualifiedProps,
              highestAccuracy: Math.max(...qualifiedProps.map(prop => prop.accuracy))
            });
          }
        });
      }
    });

    return filteredPicks;
  }, [todaysGames, currentPredictions, backtestingData]);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#0B1120] flex justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#0B1120] flex justify-center items-center p-4">
        <div className="bg-red-500/10 border border-red-500/30 rounded-xl p-6 max-w-md w-full text-center">
          <h3 className="text-xl font-semibold text-red-400 mb-2">Error Loading Picks</h3>
          <p className="text-gray-400">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#0B1120] py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Back to Dashboard Button */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex justify-start"
        >
          <Link
            to="/"
            className="inline-flex items-center gap-2 text-gray-400 hover:text-white transition-colors group"
          >
            <ArrowLeftIcon className="w-5 h-5 transition-transform group-hover:-translate-x-1" />
            <span>Back to Dashboard</span>
          </Link>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <h1 className="text-3xl font-bold text-white">Today's Top Picks</h1>
          <p className="mt-2 text-gray-400">Statistically verified predictions based on historical performance</p>
        </motion.div>

        {/* Add this explanation section before the player props section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.25 }}
          className="bg-blue-500/10 border border-blue-500/20 rounded-xl p-4"
        >
          <div className="flex items-center gap-3">
            <div className="p-2 rounded-lg bg-blue-500/20">
              <span className="text-lg">💡</span>
            </div>
            <div>
              <h3 className="text-white font-medium mb-1">Understanding Player Props</h3>
              <p className="text-sm text-gray-400">
                Our AI analyzes each player's recent performance and historical data to predict their stats for today's game. 
                Higher confidence scores and success rates indicate stronger predictions based on past accuracy.
              </p>
            </div>
          </div>
        </motion.div>

        {/* Player Props Picks */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-gray-800/50 rounded-xl p-6"
        >
          <div className="flex items-center gap-2 mb-6">
            <FireIcon className="w-6 h-6 text-red-500" />
            <h2 className="text-xl font-semibold text-white">Player Props Picks</h2>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {topPicks?.playerProps.length === 0 ? (
              <div className="col-span-full text-center py-8 text-gray-400">
                No high-probability player props picks available for today
              </div>
            ) : (
              [...topPicks.playerProps]
                .sort((a, b) => b.highestAccuracy - a.highestAccuracy)
                .map((pick, index) => (
                  <motion.div
                    key={`${pick.player}-${index}`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="bg-gray-800/40 backdrop-blur-sm rounded-xl p-5 border border-white/[0.05] hover:border-white/[0.1] transition-colors"
                  >
                    <div className="flex justify-between items-center mb-4">
                      <div className="text-white font-semibold text-xl">{pick.player}</div>
                      <div className="flex items-center gap-2">
                        <a
                          href={`https://sportsbook.draftkings.com/players/nba/${pick.player.toLowerCase().replace(/\s+/g, '-')}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center gap-2 px-3 py-1.5 rounded-lg bg-[#1A98FF]/10 hover:bg-[#1A98FF]/20 transition-colors border border-[#1A98FF]/20 hover:border-[#1A98FF]/30"
                        >
                          <img 
                            src="/images/sportsbooks/draftkings.png"
                            alt="DraftKings"
                            className="w-4 h-4 object-contain brightness-200"
                          />
                          <span className="text-xs text-[#1A98FF]">DraftKings</span>
                        </a>
                        <a
                          href="https://prizepicks.onelink.me/FjtC/te8p1tcc"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center gap-2 px-3 py-1.5 rounded-lg bg-indigo-500/10 hover:bg-indigo-500/20 transition-colors border border-indigo-500/20 hover:border-indigo-500/30"
                        >
                          <img 
                            src="/images/sportsbooks/prizepicks.png"
                            alt="PrizePicks"
                            className="w-4 h-4 object-contain"
                          />
                          <span className="text-xs text-indigo-400">PrizePicks</span>
                        </a>
                      </div>
                    </div>
                    <div className="space-y-4">
                      {pick.props.map((prop, propIndex) => (
                        <div key={propIndex} className="bg-gray-900/30 rounded-lg p-4">
                          <div className="flex items-center justify-between mb-2">
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <span className="text-gray-400 text-sm">
                                  {prop.stat.charAt(0).toUpperCase() + prop.stat.slice(1)}
                                </span>
                                <span className="text-2xl font-bold text-white">
                                  {prop.value}
                                </span>
                              </div>
                              {prop.accuracy >= 80 && (
                                <span className="px-2 py-1 text-xs bg-green-500/20 text-green-400 rounded-lg border border-green-500/20">
                                  High Success Rate
                                </span>
                              )}
                            </div>
                            <div className="text-right">
                              <div className="text-indigo-400 font-bold text-lg">
                                {Math.round(prop.confidence)}%
                              </div>
                              <div className="text-xs text-gray-500">Confidence</div>
                            </div>
                          </div>
                          <div className="text-sm text-gray-400">
                            Historical Success: {prop.accuracy.toFixed(0)}%
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="mt-4 pt-3 border-t border-white/[0.05]">
                      <div className="bg-indigo-500/10 border border-indigo-500/20 rounded-xl p-3">
                        <p className="text-sm text-indigo-400">
                          🏆 Pro Tip: PrizePicks offers the highest success rate for player props payouts!
                        </p>
                      </div>
                    </div>
                  </motion.div>
                ))
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default TopPicks; 