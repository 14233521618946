import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useSport } from '../../contexts/SportContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useSpring, animated } from '@react-spring/web';

const Navbar = () => {
  const [showNBATooltip, setShowNBATooltip] = useState(false);
  const [showNHLTooltip, setShowNHLTooltip] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const userMenuRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { activeSport, setActiveSport } = useSport();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Handle click outside of user menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // React Spring animation for logo
  const logoSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { tension: 300, friction: 20 }
  });

  const handleLogout = async () => {
    try {
      setShowUserMenu(false);
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  // Framer Motion variants
  const menuVariants = {
    open: {
      opacity: 1,
      height: 'auto',
      transition: { duration: 0.3, ease: 'easeInOut' }
    },
    closed: {
      opacity: 0,
      height: 0,
      transition: { duration: 0.3, ease: 'easeInOut' }
    }
  };

  const sportButtonVariants = {
    hover: { scale: 1.05, transition: { duration: 0.2 } },
    tap: { scale: 0.95 },
    initial: { scale: 1 }
  };

  return (
    <nav className="sticky top-0 z-50 backdrop-blur-md bg-[#0B1120]/80 border-b border-white/10">
      <div className="max-w-[1440px] mx-auto px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <animated.div style={logoSpring} className="flex-shrink-0">
            <Link to="/" className="flex items-center">
              <span className="text-lg sm:text-xl font-semibold tracking-tight">
                <span className="text-gray-100">Strategix</span>
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 font-bold">Picks</span>
              </span>
            </Link>
          </animated.div>

          {/* Mobile menu button */}
          <motion.div 
            className="md:hidden"
            whileTap={{ scale: 0.95 }}
          >
            <motion.button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="relative overflow-hidden group p-2.5 rounded-xl bg-gradient-to-br from-blue-500/5 to-purple-500/5 border border-white/[0.08] hover:border-white/[0.15] transition-all duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-purple-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <div className="relative w-6 h-5 flex flex-col justify-between transform transition-all duration-300">
                <motion.span
                  className="w-6 h-0.5 bg-gradient-to-r from-blue-400 to-blue-500 rounded-full transform origin-left transition-all duration-300"
                  animate={isMobileMenuOpen ? { rotate: 45, y: 0, width: "100%" } : { rotate: 0, y: 0, width: "100%" }}
                />
                <motion.span
                  className="w-4 h-0.5 bg-gradient-to-r from-blue-400 to-blue-500 rounded-full transform transition-all duration-300"
                  animate={isMobileMenuOpen ? { opacity: 0, x: -10 } : { opacity: 1, x: 0 }}
                />
                <motion.span
                  className="w-6 h-0.5 bg-gradient-to-r from-blue-400 to-blue-500 rounded-full transform origin-left transition-all duration-300"
                  animate={isMobileMenuOpen ? { rotate: -45, y: 0, width: "100%" } : { rotate: 0, y: 0, width: "100%" }}
                />
              </div>
            </motion.button>
          </motion.div>

          {/* Center Navigation */}
          <div className="hidden md:flex items-center space-x-2">
            <motion.button 
              variants={sportButtonVariants}
              whileHover="hover"
              whileTap="tap"
              initial="initial"
              onClick={() => setActiveSport('NBA')}
              className={`relative overflow-hidden group px-6 py-2 rounded-xl transition-all duration-300
                ${activeSport === 'NBA' 
                  ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white' 
                  : 'bg-gradient-to-r from-gray-800/50 to-gray-900/50 text-gray-300 hover:text-white'}`}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-400/20 to-blue-600/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <div className="relative flex items-center space-x-2">
                <img src="/images/nba-logo.svg" alt="NBA" className="w-5 h-5" />
                <span className="font-medium">NBA</span>
              </div>
            </motion.button>

            <motion.div 
              className="relative"
              whileHover="hover"
              whileTap="tap"
              initial="initial"
            >
              <button 
                className="relative overflow-hidden group px-6 py-2 rounded-xl bg-gradient-to-r from-gray-800/50 to-gray-900/50 text-gray-300 hover:text-white transition-all duration-300"
                onMouseEnter={() => setShowNHLTooltip(true)}
                onMouseLeave={() => setShowNHLTooltip(false)}
              >
                <div className="absolute inset-0 bg-gradient-to-r from-gray-700/20 to-gray-800/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="relative flex items-center space-x-2">
                  <img src="/images/nhl-logo.svg" alt="NHL" className="w-5 h-5" />
                  <span className="font-medium">NHL</span>
                </div>
              </button>
              <AnimatePresence>
                {showNHLTooltip && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="absolute -bottom-12 left-1/2 transform -translate-x-1/2 px-4 py-2 rounded-lg bg-gray-800/90 backdrop-blur-sm border border-white/10 shadow-xl"
                  >
                    <div className="text-sm font-medium text-white whitespace-nowrap">Coming Soon</div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </div>

          {/* Account */}
          <div className="flex items-center">
            {user ? (
              <motion.div 
                ref={userMenuRef}
                className="relative"
                whileTap={{ scale: 0.98 }}
              >
                <motion.button
                  onClick={() => setShowUserMenu(!showUserMenu)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className={`flex items-center space-x-2 sm:space-x-3 px-2 sm:px-4 py-2 rounded-xl bg-gradient-to-r from-blue-500/10 to-purple-500/10 hover:from-blue-500/20 hover:to-purple-500/20 border border-white/10 hover:border-white/20 transition-all duration-300 ${showUserMenu ? 'bg-white/10' : ''}`}
                >
                  <div className="relative">
                    <div className="w-7 h-7 sm:w-8 sm:h-8 rounded-lg bg-gradient-to-br from-blue-400 to-blue-600 flex items-center justify-center text-white font-medium shadow-lg">
                      {user.name ? user.name[0].toUpperCase() : '?'}
                    </div>
                    <div className="absolute -bottom-0.5 -right-0.5 w-2.5 h-2.5 bg-green-500 rounded-full border-2 border-[#0B1120]" />
                  </div>
                  <span className="hidden sm:block text-gray-200 font-medium">{user.name || 'Account'}</span>
                  <motion.div
                    animate={{ rotate: showUserMenu ? 180 : 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </motion.div>
                </motion.button>
                
                <AnimatePresence>
                  {showUserMenu && (
                    <motion.div 
                      initial={{ opacity: 0, y: 10, scale: 0.95 }}
                      animate={{ 
                        opacity: 1,
                        y: 0,
                        scale: 1,
                        transition: { duration: 0.2 }
                      }}
                      exit={{ 
                        opacity: 0,
                        y: 10,
                        scale: 0.95,
                        transition: { duration: 0.15 }
                      }}
                      className="absolute right-0 w-64 mt-2 pt-2 pb-1 bg-gradient-to-b from-gray-900/90 to-gray-800/90 backdrop-blur-md rounded-xl border border-white/10 shadow-2xl z-50"
                    >
                      <div className="px-4 py-2 border-b border-white/5">
                        <p className="text-sm text-gray-400">Signed in as</p>
                        <p className="text-sm font-medium text-white">{user.email}</p>
                      </div>
                      
                      <div className="px-2 py-1.5">
                        <Link
                          to="/"
                          onClick={() => setShowUserMenu(false)}
                          className="flex items-center space-x-3 px-3 py-2 rounded-lg text-gray-300 hover:text-white hover:bg-white/5 transition-all duration-200"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                          </svg>
                          <span className="text-sm font-medium">Dashboard</span>
                        </Link>

                        <Link
                          to="/top-picks"
                          onClick={() => setShowUserMenu(false)}
                          className="flex items-center space-x-3 px-3 py-2 rounded-lg text-gray-300 hover:text-white hover:bg-white/5 transition-all duration-200"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                          </svg>
                          <span className="text-sm font-medium">Top Picks</span>
                        </Link>
                        
                        <Link
                          to="/account"
                          onClick={() => setShowUserMenu(false)}
                          className="flex items-center space-x-3 px-3 py-2 rounded-lg text-gray-300 hover:text-white hover:bg-white/5 transition-all duration-200"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                          </svg>
                          <span className="text-sm font-medium">Profile Settings</span>
                        </Link>
                        
                        <div className="px-2 py-1.5 mt-1 border-t border-white/5">
                          <button
                            onClick={handleLogout}
                            className="flex items-center space-x-3 px-3 py-2 w-full rounded-lg text-red-400 hover:text-red-300 hover:bg-red-500/10 transition-all duration-200"
                          >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                            </svg>
                            <span className="text-sm font-medium">Sign Out</span>
                          </button>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ) : (
              <div className="flex items-center space-x-2 sm:space-x-4">
                <Link
                  to="/login"
                  className="px-3 sm:px-4 py-2 rounded-xl text-gray-300 hover:text-white transition-colors duration-200"
                >
                  <span className="text-sm font-medium">Login</span>
                </Link>
                <Link
                  to="/register"
                  className="relative overflow-hidden group px-3 sm:px-4 py-2 rounded-xl bg-gradient-to-r from-blue-500 to-blue-600 text-white transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/25"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-400/20 to-blue-600/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <span className="relative text-sm font-medium">Register</span>
                </Link>
              </div>
            )}
          </div>
        </div>

        {/* Mobile menu */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              variants={menuVariants}
              initial="closed"
              animate="open"
              exit="closed"
              className="md:hidden overflow-hidden"
            >
              <div className="px-2 pt-2 pb-3 space-y-2">
                <motion.button
                  variants={sportButtonVariants}
                  whileHover="hover"
                  whileTap="tap"
                  initial="initial"
                  onClick={() => {
                    setActiveSport('NBA');
                    setIsMobileMenuOpen(false);
                  }}
                  className={`w-full relative overflow-hidden group px-4 py-3 rounded-xl transition-all duration-300
                    ${activeSport === 'NBA'
                      ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white'
                      : 'bg-gradient-to-r from-gray-800/50 to-gray-900/50 text-gray-300'}`}
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-400/20 to-blue-600/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <div className="relative flex items-center justify-center space-x-2">
                    <img src="/images/nba-logo.svg" alt="NBA" className="w-5 h-5" />
                    <span className="font-medium">NBA</span>
                  </div>
                </motion.button>

                <motion.button
                  variants={sportButtonVariants}
                  whileHover="hover"
                  whileTap="tap"
                  initial="initial"
                  className="w-full relative overflow-hidden group px-4 py-3 rounded-xl bg-gradient-to-r from-gray-800/50 to-gray-900/50 text-gray-300 transition-all duration-300"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-gray-700/20 to-gray-800/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <div className="relative flex items-center justify-center space-x-2">
                    <img src="/images/nhl-logo.svg" alt="NHL" className="w-5 h-5" />
                    <span className="font-medium">NHL</span>
                    <span className="text-xs text-gray-400">(Coming Soon)</span>
                  </div>
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Navbar;